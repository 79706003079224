<template>
	<div class="cbox">
		<div class="headbar">
			
			<div style="padding-top: 14px;">
				<el-breadcrumb>
					<el-breadcrumb-item>基础设置</el-breadcrumb-item>
					<el-breadcrumb-item>教师角色</el-breadcrumb-item>

				</el-breadcrumb>
			</div>

		</div>
		<div class="bcontent">
			<div style="height: 100%;">
				<el-table :data="DataList" stripe border>
					<el-table-column type="index" label="编号" width="80">
					</el-table-column>
					<el-table-column prop="ucode" label="教师编号" width="120">
					</el-table-column>
					<el-table-column prop="username" label="头像" width="70">
						<template slot-scope="scope">

							<img v-if="scope.row.avatar" :src="scope.row.avatar"
								style="width: 40px; height: 40px; border-radius: 4px;">

						</template>
					</el-table-column>

					<el-table-column prop="username" label="教师姓名" min-width="120">
					</el-table-column>


					<el-table-column prop="deptname" label="所在部门" min-width="180">
						<template slot-scope="scope">

							<span style="display: inline-block; margin-right: 10px;"
								v-for="(dept,idx) in scope.row.dept" :key="idx">{{dept.dept_name}}</span>


						</template>
					</el-table-column>
					<el-table-column prop="rolename" label="所属角色" min-width="180">
						<template slot-scope="scope">

							<span class="roleitem" v-for="(r,idx) in scope.row.role" :key="idx">{{r.role_name}}</span>


						</template>
					</el-table-column>

					<el-table-column fixed="right" label="操作" width="150">
						<template slot-scope="scope">

							<el-button type="text" @click="viewDetail(scope.row)">设置角色</el-button>


						</template>
					</el-table-column>


				</el-table>
			</div>
		</div>



		<el-dialog title="角色权限" :visible.sync="menuVisible" :close-on-click-modal="false">


			<div>
				<el-checkbox-group v-model="CheckedTreeNodes">
					<el-checkbox border style="margin-bottom: 10px;" v-for="(item,idx) in TreeData"
						:label="item.role_name" :key="idx"></el-checkbox>

				</el-checkbox-group>

			</div>

			<el-row>
				<el-col :span="22" style="text-align: right;">
					<el-button type="primary" @click="saveRoleMenu">保存</el-button>
				</el-col>
			</el-row>

		</el-dialog>

	</div>
</template>

<script>
	export default {

		data() {
			return {
				DataList: [],
				dialogVisible: false,
				menuVisible: false,
				EditItem: {
					id: "",
					rolename: "",
					roledesc: "",
					menuids: "",
					sort: ""


				},
				page: {
					current_page: 1,
					next_page: 1,
					pre_page: 1,
					total_page: 1,
					count: 0
				},
				TreeData: [],
				defaultProps: {
					children: 'children',
					label: 'rolename'
				},
				CheckedTreeNodes: []
			};
		},
		mounted() {
			this.getList(1);
		},
		methods: {
			getList(page) {
				this.$http.post("/api/zichan_role_teachers", {
					page: page
				}).then(
					res => {

						this.DataList = res.data;
						this.getRoleList();

					})
			},

			getRoleList() {
				this.$http.post('/api/zichan_sys_role_list', {
					pagesize: 100
				}).then(res => {
					this.TreeData = res.data;
				})

			},


			viewDetail(item) {
				this.menuVisible = true;
				this.EditItem = item;
				if (item.role) {
					this.CheckedTreeNodes = item.role.map(e => {
						return e.role_name
					})

				} else {
					this.CheckedTreeNodes = [];
				}

			},

			saveRoleMenu() {

				//获取角色id
				let roleid = "";
				if (this.CheckedTreeNodes.length > 0) {
					let roleids = [];
					for (let item of this.CheckedTreeNodes) {
						for (let item2 of this.TreeData) {
							if (item == item2.role_name) {
								roleids.push(item2.id);
								break;
							}
						}
					}
					roleid = roleids.join(",")
					this.EditItem.roleids = roleid;
					this.$http.post("/api/zichan_set_user_role", this.EditItem).then(res => {
						this.$message({
							type: 'success',
							message: '保存成功'
						});
						this.menuVisible = false
						this.getList(this.page.current_page);
					})

				} else {
					this.EditItem.roleids = "";
					this.$http.post("/api/zichan_set_user_role", this.EditItem).then(res => {
						this.$message({
							type: 'success',
							message: '保存成功'
						});
						this.menuVisible = false
						this.getList(this.page.current_page);
					})
				}




			}
		}
	}
</script>
<style scoped="scoped">
	.roleitem {
		display: inline-block;
		border: 1px solid #5CA7E4;
		font-size: 12px;
		margin-right: 5px;
		border-radius: 2px;
		color: #5CA7E4;
		padding: 0 5px;
		line-height: 18px;
	}
</style>
